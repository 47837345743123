export const CheckNumberAfterPoint = (number: number | string) => {
    const numberAsString = number?.toString();

    if (numberAsString && numberAsString.includes(".")) {
        const beforePoint = numberAsString.split(".")[0];
        const afterPoint = numberAsString.split(".")[1];
        const result = Number(number);

        if (parseInt(beforePoint) === 0 && parseInt(afterPoint[0]) === 0 && parseInt(afterPoint[1]) === 0) {
            return result.toFixed(4);
        } else {
            return result.toFixed(2);
        }
    }

    return Number(number).toFixed(2);
};
