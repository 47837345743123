import Cookies from "universal-cookie";

import { instance } from "./authAPI";
import { processRequestQueryParams } from "./utils/apiUtils";
import { TRequestQueryParams } from "../redux/types/common/common.types";

export const reportingApi = {
    postGenerateStorageOverviewReport(cookies: Cookies, queryParams?: TRequestQueryParams) {
        return instance.post<{ url: string }>(
            `/shipments/stored-products-report/${processRequestQueryParams(queryParams || {})}`,
            {},
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    postGenerateShortStorageReport(cookies: Cookies, reqData: { date_from: string; date_to: string }, queryParams?: TRequestQueryParams) {
        return instance.post<{ url: string }>(`/rates/daily-storage-short-report/${processRequestQueryParams(queryParams || {})}`, reqData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postGenerateExtendedStorageReport(cookies: Cookies, reqData: { date_from: string; date_to: string }, queryParams?: TRequestQueryParams) {
        return instance.post<{ url: string }>(`/rates/daily-storage-extended-report/${processRequestQueryParams(queryParams || {})}`, reqData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};
