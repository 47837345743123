import Cookies from "universal-cookie";

import { instance } from "./authAPI";
import { TRequestQueryParams } from "../redux/types/common/common.types";
import { NotificationAxiosResponseType } from "../redux/types/AuthTypes/auth.types";
import { TPostSubscriptionManagementReqData, TSubscriptionManagement, TSubscriptionUser } from "../redux/types/SettingsTypes/settings.types";

export const notifications = {
    getNotifications(cookies: Cookies, limit: number) {
        return instance.get<unknown, NotificationAxiosResponseType>(`notifications/?limit=${limit}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    deleteNotification(cookies: Cookies, id: number) {
        return instance.delete(`notifications/${id}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    readNotification(cookies: Cookies, id: number[]) {
        return instance.post(
            `notifications/`,
            {
                id_list: id,
            },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    readAllNotification(cookies: Cookies) {
        return instance.post(
            `notifications/`,
            {
                select_all: true,
            },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
};

export const emailSubscriptionApi = {
    getTenantMemberEmails(cookies: Cookies) {
        return instance.get<TSubscriptionUser[]>(`notifications/tenant-emails/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getEmailSubscriptionManagement(cookies: Cookies) {
        return instance.get<TSubscriptionManagement>(`/notifications/email-management/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postEmailSubscriptionManagement(cookies: Cookies, reqData: TPostSubscriptionManagementReqData[]) {
        return instance.post(
            `/notifications/email-management/`,
            { emails: reqData },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
};
