import Cookies from "universal-cookie";
import {
    AddProductFromAmazonCategoriesType,
    AddProductFromAmazonRequest,
    EditProductDataType,
    GetProductActionTypeAxios,
    IntegrationsListTypeAxios,
    ProductsFromAmazonRootObject,
    ShipmentUploadedActionTypeAxios,
    UploadFileOfSkusResponse,
} from "../redux/types/AddProductTypes/AddProduct.types";
import {
    AddNotesIncomingShipmentsRequest,
    TPatchSubmitFinalWeightOrCbmReqData,
    TPostUkTariffAmountReqData,
    UpdateDownloadInfoRequest,
} from "../redux/types/WarehouseTypes/warehouse.types";
import { instance } from "./authAPI";

export const dashboardAPI = {
    getIntegrationsList(token: string) {
        return instance.get<unknown, IntegrationsListTypeAxios>("/integrations/products-integration/", {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    getBrands(token: string) {
        return instance.get<string[]>(`/shipments/brand/dashboard/`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    getBrandProduct(token: string, brand: string) {
        return instance.get(`/shipments/brand-details/dashboard/?brand=${window.encodeURIComponent(brand)}`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    ConfirmProductAction(formData: FormData, token: string) {
        return instance.post(`/shipments/admin/incoming-shipments/`, formData, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    AddNotesIncomingShipments(data: AddNotesIncomingShipmentsRequest, token: string) {
        return instance.post(`/shipments/notes/`, data, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    UpdateDownloadInfo(id: string, data: UpdateDownloadInfoRequest, token: string) {
        return instance.patch(`/shipments/admin/incoming-shipments/${id}/`, data, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    patchSubmitFinalWeightOrCbm(cookies: Cookies, data: TPatchSubmitFinalWeightOrCbmReqData) {
        return instance.patch(`/amazon_mws/inbound-shipment/${data.id}/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    ShipmentUploadedAction(token: string, country_code?: string) {
        return instance.get<unknown, ShipmentUploadedActionTypeAxios>(`/shipments/uploaded/dashboard/?${country_code ? `integration__country_code=${country_code}` : ""}`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    ShipmentUploadedActionAll(token: string, items: number, offset: number, search: string, country_code?: string, brand?: string) {
        return instance.get<unknown, ShipmentUploadedActionTypeAxios>(
            `/shipments/uploaded/dashboard/?limit=${items ? items : 10}&offset=${offset}${search ? `&search=${window.encodeURIComponent(search)}` : ""}${country_code && country_code !== "ALL" ? `&integration__country_code=${country_code}` : ""}${brand ? `&brand=${window.encodeURIComponent(brand)}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + token,
                },
            }
        );
    },
    getProductAction(token: string, country_code?: string) {
        return instance.get<unknown, GetProductActionTypeAxios>(`/shipments/requires-actions/dashboard/?${country_code ? `integration__country_code=${country_code}` : ""}`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    getProductActionAll(token: string, item: number, offset: number, searchTerm: string, country_code?: string, brand?: string) {
        return instance.get<unknown, GetProductActionTypeAxios>(
            `/shipments/requires-actions/dashboard/?limit=${item}&offset=${offset}${searchTerm ? `&search=${window.encodeURIComponent(searchTerm)}` : ""}${country_code ? `&integration__country_code=${country_code}` : ""}${brand ? `&brand=${window.encodeURIComponent(brand)}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + token,
                },
            }
        );
    },
    editProduct(token: string, id: string, data: EditProductDataType) {
        return instance.patch(`/shipments/uploaded/dashboard/${id}/`, data, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    deleteProduct(token: string, id: string) {
        return instance.delete(`/shipments/uploaded/dashboard/${id}/`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    deleteHSCode(cookies: Cookies, id: string) {
        return instance.delete(`/shipments/hs-codes/${id}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getProductFromAmazon(cookies: Cookies, limit: number, offset: number, searchTerm: string, integrationId?: string) {
        return instance.get<ProductsFromAmazonRootObject>(
            `/amazon_mws/amazon/import-templates/?limit=${limit}&offset=${offset}${integrationId ? "&integration__id=" + integrationId : ""}${searchTerm ? `&search=${window.encodeURIComponent(searchTerm)}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getUpdatedProductFromAmazon(token: string) {
        return instance.get("/amazon_mws/amazon/import-templates-updated/ ", {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    updateProductFromAmazon(fast_mode: boolean, token: string) {
        return instance.post(
            "/amazon_mws/amazon/import-templates/",
            {
                fast_mode: fast_mode,
            },
            {
                headers: {
                    Authorization: "Bearer " + token,
                },
            }
        );
    },
    importCustomProductAmazon(sku_list: string[], token: string) {
        return instance.post(
            "/amazon_mws/amazon/import-templates/",
            {
                sku_list,
            },
            {
                headers: {
                    Authorization: "Bearer " + token,
                },
            }
        );
    },
    addProductFromAmazon(products: AddProductFromAmazonRequest[] | null, token: string) {
        return instance.post(
            "/amazon_mws/amazon/import-templates-to-skudrop/",
            {
                products: products,
            },
            {
                headers: {
                    Authorization: "Bearer " + token,
                },
            }
        );
    },
    createAndConfirmPaymentIntent(token: string, formData: FormData) {
        return instance.post("/shipments/admin/tariff-management/", formData, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    postChargeUKContainerTariff(cookies: Cookies, data: FormData) {
        return instance.post("/shipments/admin/tariff-management/", data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    calculateTariff(token: string, id: string | string[], filterType: string) {
        return instance.post("shipments/admin/upload-tariff/", filterType === "AIR" ? { outgoing_shipment_ids: id } : { container_id: id[0] }, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    patchTariffAmount(id: string | string[], tariff_amount: string, token: string) {
        return instance.patch(
            `/shipments/admin/tariff-management/${id}/`,
            { tariff_amount },
            {
                headers: {
                    Authorization: "Bearer " + token,
                },
            }
        );
    },
    postTariffUkAmount(data: TPostUkTariffAmountReqData, token: string) {
        return instance.post(`/shipments/admin/tariff-container-update/`, data, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    checkOutgoingShipment(token: string, id: string) {
        return instance.get(`/shipments/check/outgoing-shipment/${id}/`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    addProductFromAmazonCategories(data: AddProductFromAmazonCategoriesType[], token: string) {
        return instance.post("/shipments/upload_shipping_declaration/", data, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    getTariffsForContainer(token: string, id: string) {
        return instance.get(`/shipments/admin/list_container_tariffs/${id}/`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    getHSCodeInfo(query: string, countryCode: string, token: string) {
        return instance.get<unknown, { data: boolean }>(`/shipments/validate-hs-code/${countryCode}/${query}/`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    getUploadSkusTemplate(integrationId: string, token: string) {
        return instance.get<unknown, { data: string }>(`/amazon_mws/bulk-product-import/?integration__id=${integrationId}`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    uploadFileWithSkus(integrationId: string, token: string, file: File) {
        const formData = new FormData();

        formData.append("file", file);

        return instance.post<unknown, { data: UploadFileOfSkusResponse[] }>(`/amazon_mws/bulk-product-import/?integration__id=${integrationId}`, formData, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
};
