import { useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { useAppDispatch, useAppSelector } from "../../../redux/ReduxStore";
import { SetComplete, SetConfirmEmail, SetThirdStep } from "../../../redux/authReducer";

import SignUp from "./SignUp";

const SignUpContainer = () => {
    const dispatch = useAppDispatch();
    const cookies = useAppSelector((state) => state.auth.cookies);

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");

    useEffect(() => {
        if (cookies.get("secondStep")) {
            dispatch(SetConfirmEmail());
        }
        if (cookies.get("thirdStep")) {
            dispatch(SetThirdStep());
        }
        if (cookies.get("complete")) {
            dispatch(SetComplete());
        }
    }, [cookies, dispatch]);

    return (
        <Elements stripe={stripePromise}>
            <SignUp />
        </Elements>
    );
};

export default SignUpContainer;
